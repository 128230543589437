.channelSettingOverview_container {
  display: grid;
  row-gap: 20px;
}

.channelSettingOverview_header_title {
  font-size: 20px;
  font-weight: 600;
}

.channelSettingOverview_avatar_wrapper {
  display: grid;
  grid-template-columns: 100px auto;
  column-gap: 10px;
  justify-content: flex-start;
  align-items: center;
}

.channelSettingOverview_avatar_img {
  width: 100px;
  height: 100px;
  border-radius: 6px;
  object-fit: cover;
}

.channelSettingOverview_avatar_button_wrapper {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 2px solid var(--color-theme-primary);
  border-radius: 6px;
  padding: 2px 10px;
}

.channelSettingOverview_avatar_button_wrapper:hover {
  background-color: #ffeeee;
}

.channelSettingOverview_avatar_button {
  color: var(--color-theme-primary);
  font-weight: 600;
  font-size: 14px;
}

.channelSettingOverview_body_wrapper {
  display: grid;
  //row-gap: 20px;
}

.channelSettingOverview_description{
  margin-bottom: 20px;
}

.channelSettingOverview_label {
  //font-weight: 600;
  font-size: 15px;
  margin-bottom: 8px;
}

.channelSettingOverview_approval_label {
  font-weight: 600;
  font-size: 15px;
}

.channelSettingOverview_approval {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
}

.channelSettingOverview_approval_left {
  display: grid;
  grid-template-rows: repeat(2, auto);
}

.channelSettingOverview_approval_right {
  font-size: 22px;
}

.channelSettingOverview_status_wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  place-items: center;
}

.channelSettingOverview_status_body {
  display: grid;
  align-items: center;
  row-gap: 10px;
  padding: 20px;
}

.channelSettingOverview_status_body.active_status {
  background-color: #f0f0f0;
}

#channelSettingOverview .channelSettingOverview_form_check {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: flex-start;
}

.channelSettingOverview_form_check_right {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: flex-start;
  column-gap: 10px;
  align-items: center;
}

.channelSettingOverview_check {
  margin-top: 2px;
}

.channelSettingOverview_form_check_right_title {
  font-weight: 600;
}

.channelSettingOverview_status_content {
  font-size: 15px;
  font-family: var(--global-font-family);
  line-height: 1.5;
}

.channelSettingOverview_button_update {
  display: flex;
  justify-content: flex-end;
}
