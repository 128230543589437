#navbar_in_header .dropdown-menu {
	width: 24.9801em;
	position: absolute;
	// border: 1px solid #eee;
	top: 100%;
	left: auto;
	margin: 0;
	-webkit-animation: fade-in-bottom .2s cubic-bezier(.39,.575,.565,1) both;
	animation: fade-in-bottom .2s cubic-bezier(.39,.575,.565,1) both;
	background-color: #fff;
	background-color: var(--color-theme-white-box);
	// padding: 0;
	border-radius: .313em;
	border-radius: var(--border-radius);
	-webkit-box-shadow: 0 14px 54px rgba(0,0,0,.03);
	box-shadow: 0 14px 54px rgba(0,0,0,.03);
	-webkit-box-shadow: var(--global-box-shadow);
	box-shadow: var(--global-box-shadow);
	-webkit-transition: all .45s ease-in-out;
	transition: all .45s ease-in-out;
  box-shadow: #64646f33 0 7px 29px;

}

#user_profile .dropdown-menu {
	padding: 0;
	margin: 0;
	border: none;
	left: auto;
	right: 0;
	width: 16.125em;
	background: #fff;
	background: var(--color-theme-white-box);
	-webkit-box-shadow: 0 14px 54px rgba(0,0,0,.03);
	box-shadow: 0 14px 54px rgba(0,0,0,.03);
	-webkit-box-shadow: var(--global-box-shadow);
	box-shadow: var(--global-box-shadow);
	-webkit-animation: fade-in-bottom .2s cubic-bezier(.39,.575,.565,1) both;
	animation: fade-in-bottom .2s cubic-bezier(.39,.575,.565,1) both;
	-webkit-transition: all .45s ease-in-out;
	transition: all .45s ease-in-out;
}

.dropdown-profile .user-profile-menu {
	list-style: none;
	padding: 2em 2em 0;
	margin: 0;
}

.dropdown-menu {
	background-color: var(--color-theme-white-box);
	color: var(--global-font-color);
	border: .0625em solid var(--border-color-light);
	-webkit-box-shadow: var(--global-box-shadow);
	box-shadow: var(--global-box-shadow);
	z-index: 98;
}


.topbar_customer {
  padding: 0.45rem 0
}
.title_mission {
  // color: white;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  font-size: 14px;
}

.show_modal {
  bottom: 50px;
  left: 30px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 300px;
  z-index: 99999;
}

.icon_like {
  margin-top: 30px;
  margin-left: 40px;
  background-color: #3a86ff;
  z-index: 1001;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
}

.new_bie {
  border: none;
  max-width: 400px;
  padding: 6px 24px;

  @media (min-width: 990px) and (max-width: 1050px) {
    max-width: 280px;
  }

  @media (max-width: 1050px) {
    display: none;
  }
}
.animation_arrow {
  right: 206px;

  @media (max-width: 1100px) {
    right: 68px
  }
}
.new_bie_back {
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.animation_action {
  position: absolute;
  top: 0;
  bottom: 40vh;
  left: 0;
  right: 0;
  z-index: 99999;
}
.gift_mission {
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  z-index: 1001;
  position: fixed;
  left: 0;
  right: 0;
  margin-top: -100px
}

.header_title_button {
  border: none;
  background: none;
}

.topbar_user_avatar {
    width: 35px;
    height: 35px;
    border-radius: 20px;
}

  .topbar_user  {}

  .topbar_user .dropdown-toggle:after {
    display: none;
  }

  .user_profile .dropdown-menu {
    width: 300px !important
  }


  .topbar_user_dropdown a.dropdown-toggle {
    jamviet: mark_for_later
  }

  .topbar_user_dropdown .dropdown-menu .topbar_user_dropdown_wrap {
    padding: 8px 15px;
    display: flex;
    gap: 10px;
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    // align-items: center;
  }

  .topbar_user_dropdown .dropdown-menu .topbar_user_dropdown_wrap .topbar_user_dropdown_avatar img{
    width: 60px;
    height: 60px;
    border-radius: 60px;
  }
  .topbar_user_dropdown .dropdown-menu .topbar_user_dropdown_wrap .topbar_user_dropdown_content{
      // width: 165px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
  }
  .number_notification {
    background-color: #ef233c;
    padding: 2px;
    font-size: 10px;
    border-radius: 30px;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 22px;
    height: 22px;
    align-items: center;
    display: flex;
    justify-content: center;
    color: white
  }

  .topbar_user .topbar_activator_icon {
      background-color: #eee;
      padding: 8px;
      border-radius: 50%;
      width: 35px;
      height: auto;
  }
  .topbar_user .topbar_activator_icon:hover {
    background-color: #d6d6d6;
  }

  #navbar_in_header .topbar_dropdown_heading {
    border-bottom: .0625em solid var(--border-color-light);
    padding: 1em 0;
    margin: 0 2em;

  }
  #navbar_in_header .title_header {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
  }

  .navbar-nav .nav-link.active img, .navbar-nav .nav-link.show img{
    background-color: #c9c9c9;
  }

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .topbar_user_message_dropdown  .dropdown-menu,
    .topbar_channel_dropdown   .dropdown-menu,
    .topbar_user_notification_dropdown  .dropdown-menu {
      right: 0 !important;
      left: 0 !important;
      top: 60px !important;
      width: 100% !important;
      height: 100% !important;
      z-index: 9999 !important;
      position: fixed !important;
    }

    #navbar_in_header .dropdown-menu .item-body {
      // height: 100vh !important;
      // min-height: 100vh !important;
      // max-height: 100vh !important;
      height: calc(100vh - 160px) !important;
      min-height: calc(100vh - 160px) !important;
      max-height: calc(100vh - 160px) !important;
      overflow-y: auto;
    }

  }
