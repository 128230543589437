.channelSettingInvite_container {
  display: grid;
  row-gap: 20px;
}

.channelSettingInvite_header_title {
  font-size: 20px;
  font-weight: 600;
}

.channelSettingInvite_body {
  display: grid;
  row-gap: 10px;
}

.channelSettingInvite_body_title {
  font-size: 15px;
  display: flex;
}

#channelSettingInvite .channelSettingInvite_body_button {
  max-width: auto;
  font-size: 15px;
}

.channelSettingInvite_footer {
  display: grid;
  row-gap: 10px;
}

.channelSettingInvite_footer_button {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 10px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e5e5e5;
  width: 100%;
  padding: 8px 16px;
  border-radius: 6px;
}

.channelSettingInvite_footer_button_title {
  display: flex;
  flex-wrap: wrap;
}

#channelSettingInvite .channelSettingInvite_footer_button_text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
}
