/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/

body,
body.css_prefix-learnpress,
body.learnpress {
  background: var(--global-body-bgcolor);
  color: var(--global-font-color);
  font-family: var(--global-font-family);
  font-size: var(--font-size-body);
  line-height: var(--font-line-height-body);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--highlight-font-family);
  font-stretch: condensed;
  clear: both;
  margin: 0.625em 0;
  word-break: break-word;
  text-transform: capitalize;
}

h1,
.h1 {
  color: var(--global-font-title);
  // font-size: var(--font-size-h1);
  line-height: var(--font-line-height-h1);
  letter-spacing: var(--font-letter-spacing-h1);
  font-weight: var(--font-weight-h1);
}

h2,
.h2 {
  color: var(--global-font-title);
  // font-size: var(--font-size-h2);
  line-height: var(--font-line-height-h2);
  letter-spacing: var(--font-letter-spacing-h2);
  font-weight: var(--font-weight-h2);
}

h3,
.h3 {
  color: var(--global-font-title);
  // font-size: var(--font-size-h3);
  line-height: var(--font-line-height-h3);
  letter-spacing: var(--font-letter-spacing-h3);
  font-weight: var(--font-weight-h3);
}

h4,
.h4 {
  color: var(--global-font-title);
  // font-size: var(--font-size-h4);
  line-height: var(--font-line-height-h4);
  letter-spacing: var(--font-letter-spacing-h4);
  font-weight: var(--font-weight-h4);
}

h5,
.h5 {
  color: var(--global-font-title);
  // font-size: var(--font-size-h5);
  line-height: var(--font-line-height-h5);
  letter-spacing: var(--font-letter-spacing-h5);
  font-weight: var(--font-weight-h5);
}

h6,
.h6 {
  color: var(--global-font-title);
  // font-size: var(--font-size-h6);
  line-height: var(--font-line-height-h6);
  letter-spacing: var(--font-letter-spacing-h6);
  font-weight: var(--font-weight-h6);
}

button,
input,
select,
optgroup,
textarea {
  color: var(--global-font-color);
}

small,
.text_small {
  font-size: var(--font-size-normal);
}

.overflow-hidden {
  overflow: hidden;
}

.z-index-minus {
  z-index: var(--z-index-minus);
}

.letter-spacing {
  letter-spacing: var(--letter-spacing);
}

a {
  color: var(--color-theme-primary);
}

:is(h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6) :is(a, a:visited) {
  font-size: inherit;
  color: inherit;
}

p {
  word-break: break-word;
  margin: 1em 0;
  line-height: var(--font-line-height-p);
}

pre {
  background: var(--color-theme-white-box);
  color: var(--global-font-color);
  padding: 1em;
  border: 0.0625em solid var(--color-theme-primary);
}

a:focus {
  outline: none !important;
}
.css_prefix h1,
body.buddypress h1,
.css_prefix h2,
body.buddypress h2,
.css_prefix h3,
body.buddypress h3,
.css_prefix h4,
body.buddypress h4,
.css_prefix h5,
body.buddypress h5,
.css_prefix h6,
body.buddypress h6 {
  margin: 0;
}

*::-moz-selection {
  background: var(--color-theme-primary);
  color: var(--color-theme-white);
  text-shadow: none;
}

::-moz-selection {
  background: var(--color-theme-primary);
  color: var(--color-theme-white);
  text-shadow: none;
}

::selection {
  background: var(--color-theme-primary);
  color: var(--color-theme-white);
  text-shadow: none;
}
