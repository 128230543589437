.channelSettingLevel_container {
  display: grid;
  row-gap: 20px;
}

.channelSettingLevel_header_title {
  font-size: 20px;
  font-weight: 600;
}

.channelSettingLevel_body_add_level {
  display: flex;
  justify-content: flex-end;
}

.channelSettingLevel_body_item {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f1f5;
  padding: 8px;
  cursor: pointer;
}

.channelSettingLevel_body_item.edit {
  grid-template-columns: 1fr;
}

.levelItem_form_wrapper {
  display: grid;
  row-gap: 20px;
}

.levelItem_form_item {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
}

.levelItem_form_button {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 10px;
  justify-content: flex-end;
}

.channelSettingLevel_body_list_button {
  display: none;
  grid-template-columns: auto auto;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
}

.channelSettingLevel_body_item:hover .channelSettingLevel_body_list_button {
  display: grid;
}

.channelSettingLevel_body_item:first-child:hover .channelSettingLevel_body_list_button {
  grid-template-columns: auto;
}

.addLevel_form_wrapper {
  display: grid;
  row-gap: 20px;
}

.addLevel_form_item {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.addLevel_form_button {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 10px;
  justify-content: flex-end;
}
