.channelSettingPoint_container {
  display: grid;
  row-gap: 20px;
}

.channelSettingPoint_header_title {
  font-size: 20px;
  font-weight: 600;
}

.channelSettingPoint_form_wrapper {
  display: grid;
  row-gap: 10px;
}

.channelSettingPoint_form_item {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  padding: 16px;
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.channelSettingPoint_button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
