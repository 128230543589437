.itemInbox_container {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  padding: 12px 16px;
  position: relative;
}

.itemInbox_content {
  display: grid;
  grid-template-rows: auto auto;
}

.itemInbox_avatar_client {
  width: 46px;
  height: 46px;
  object-fit: cover;
  border-radius: 50%;
}

.itemInbox_content_name_client {
  max-width: 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.itemInbox_content_message_client {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.unread_messages {
  border-radius: 12px;
  background: #f3f4f5;
}
