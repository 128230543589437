.channelSettingPage_container {
  display: flex;
}

.channelSettingPage_left {
  background-color: #fff;
  border-radius: 6px;
  height: fit-content;
  padding: 10px 20px;
  position: sticky;
  top: 80px;
  width: 30%;
}

.channelSettingPage_right {
  background-color: #fff;
  border-radius: 6px;
  flex: 1 1;
  margin-left: 20px;
  padding: 20px;
  height: auto;
}

.channelSettingPage_left_item_link.active {
  color: var(--color-theme-primary);
}

.channelSettingPage_left_item_link {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: 10px;
  padding: 10px 0;
  text-decoration: none;
  color: unset;
}

.channelSettingPage_left_item_title {
  font-weight: 600;
  text-align: start;
  font-size: 15px;
}

@media screen and (max-width: 920px) {
  .channelSettingPage_container {
    flex-direction: column;
  }

  .channelSettingPage_left {
    position: unset;
    width: 100%;
  }

  .channelSettingPage_right {
    margin-left: unset;
    margin-top: 20px;
  }
}
