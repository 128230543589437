@import url('./global.scss');
@import url('./typography.scss');
@import url('./custom.scss');
@import url('./dropdown.scss');

/** có những người rất thích ăn uống gần gốc cây cổ thụ thật vắng vẻ */

* {
  font-family: var(--global-font-family)
}


.bg-navbar-light {
  background-color: #fff !important;
  border-bottom: 1px solid #eee;
}

#main_container {
  padding-top: 0;
	margin-left: 16.862em;
	margin-left: var(--sidebar-width);
	-webkit-transition: margin-left .45s ease;
	transition: margin-left .45s ease;
}

.mainFrame {
	padding: 2em 0;
	padding: 2em 0 var(--global-page-bottom-spacing);
}

.container-extra-small{
  max-width: 566px;
}

.container-small{
  max-width: 767px;
}

.container-medium{
  max-width: 991px;
}

.container-large{
  max-width: 1199px;
}


#page {
  background-color: #f9f9f9;
}

.custom_progressbar {
    min-width: 1%;
    max-width: 100% !important;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    background-color: brown;
    height: 2px;
}

.offcanvas{
  @media screen and  (max-width: 780px) and (min-width: 372px) {
    width: 80% !important;
  }
}
.paginationtable_wrap {
    padding: 10px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 10px;
    flex-wrap: wrap;


    .paginationtable_item {
      cursor: pointer;
      padding: 0 10px;
    }

    .paginationtable_item.disabled {
      cursor: default;
    }

    .paginationtable_item.active {
      cursor: default;
      border-radius: 6px;
      border: 1px solid #ccc;
      padding: 0 10px;
    }
  }

  .header_search {
    position: relative;
  }

  .header_search input {
    border-radius: 40px;
    transition: width 0.1s ease-in-out;
    max-width: 30vw;
    min-width: 30px;
    border: none;
    background-color: #efefef !important;
    padding-left: 35px;
  }

  .header_container_search_tab_icon_search {
    display: flex;
    overflow: hidden;
    position: absolute;
    left: 12px;
    top: 10px;
  }

  // .header_search input:focus{
  //   width: 286px;
  // }

  /*
  * Sidebar
  */


  .sidebar {
    display: block;
    position: fixed;
    width: 100%;
    max-width: 16.862em;
    max-width: var(--sidebar-width);
    top: 0;
    bottom: 0;
    z-index: 1031;
    -webkit-transition: max-width .45s ease;
    transition: max-width .45s ease;
    background-color: #fff;
    background-color: var(--color-theme-white-box);
    -webkit-box-shadow: 0 14px 54px rgba(0,0,0,.03);
    box-shadow: 0 14px 54px rgba(0,0,0,.03);
    -webkit-box-shadow: var(--global-box-shadow);
    box-shadow: var(--global-box-shadow);
  }

  .sidebar.fullwidth {
    width: 100%;
    max-width: unset;
    left: 0;
        @media screen and  (max-width: 780px) and (min-width: 372px) {
      width: 80%;
    }
  }

  .offcanvas-header .btn-close {
    z-index: 2000;
  }

  .sidebar .sidebar-header {
    /* border-bottom: .063em solid #f1f1f1; */
    border-bottom: .063em solid var(--border-color-light);
    padding: 0.864em 2em;
    overflow: hidden;
  }

  .sidebar_wrap {
    background-color: transparent;
    height: 100vh;
    overflow-y: scroll;
    position: relative;
    width: auto;
  }


  .sidebar_wrap .sidebar_title {
    font-size: 14px;
    color: #626262;
    margin: 0 0 5px 0 !important;
    font-weight: bold;
    margin-bottom: -11px;
  }

  .sidebar-body {
    padding: 2em;
  }

  .sidebar .sidebar-footer {
    -webkit-transition: all .45s ease;
    transition: all .45s ease;
    margin: 2em 0 4em 0;
  }

  .sidebar .image-icon-sidebar{
    width: 20px;
    height: 20px;
    object-fit: fill;
  }


/*
* menu_left ...
*/

.menu_left {
    margin: 15px auto;
    padding: 0;
    list-style: none;

    .sub-menu {
      list-style: none;
      background: #f8f9fa;
      background: var(--global-body-bgcolor);
      padding: .5em 1em 1em;
      border-radius: 0 0 .313em .313em;
      border-radius: 0 0 var(--border-radius) var(--border-radius);
      -webkit-transition: all .45s ease;
      transition: all .45s ease;
    }

    li{
      -webkit-transition: color .45s ease,padding-left .45s ease;
      transition: color .45s ease,padding-left .45s ease;
      position: relative;
      margin: .5em 0 0;
    }
    li a {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      padding: 0.425em 1em;
      text-decoration: none;
      color: var(--global-font-color)
    }
    li a  {
      text-transform: capitalize;
      font-size: .875em;
      font-size: var(--font-size-normal);
      font-weight: 600;
      font-weight: var(--font-weight-semi-bold);
      line-height: 1.6em;
      font-family: var(--highlight-font-family);
      -webkit-transition: all .45s ease;
      transition: all .45s ease;
      border-radius: var(--border-radius);
    }
    li a.active {
      background-color: var(--color-theme-primary);
      color: var(--color-theme-white)
    }
    li a:hover {
      background-color: #2f65b9;
      background-color: var(--color-theme-primary-light);
      color: #2f65b9;
      color: var(--color-theme-primary);
    }
    li a .name {
      padding-bottom: 0;
      -ms-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
      justify-items: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-transform: capitalize;
      font-size: 15px;
  }
  li a .icon{
    display: inline-block;
    vertical-align: middle;
    width: 1.428em;
    height: 1.428em;
    max-width: 1.428em;
    font-weight: 400;
    font-size: 1.1em;
  }

  .has-submenu {
    overflow: auto;
    -webkit-transition: all .45s ease;
    transition: all .45s ease;


    .sub-menu {
      -webkit-transition: all .45s ease;
      transition: all .45s ease;
      a {
        font-size: 12px;
      }
    }

    .rotate-icon {
      transform: rotate(180deg);
      transition-property: transform;
      position: absolute;
      right: 6px;
      top: 6px;
      text-align: center;
      -webkit-transition: all .45s ease;
      transition: all .45s ease;

    }
    a.j .rotate-icon {
      transform: rotate(0deg);
    }

    .sub-active {
      background-color: #ffc3bf;
      background-color: var(--color-theme-primary-light);
    }

  }
}


.heart {
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  height: 50px;
  width: 50px;
  background-image:url( './icons/web_heart.png');
  background-position: left;
  background-repeat:no-repeat;
  background-size:2900%;
}

.heart.span {}

.heart.liked {
  background-position:right;
}

.is_animating {
  animation: heart-burst .8s steps(28) 1;
}

@keyframes heart-burst {
  from {background-position:left;}
  to { background-position:right;}
}







#site_footer {
  margin: 1em;
  font-size: 12px;
  color: #737373;
  text-decoration: none;
}

#site_footer a{
  color: #737373;
}
#site_footer a:hover{
  text-decoration: underline;
}

#site_footer .link-list{
  padding: 0;
  margin: 5px 0;
}
#site_footer .link-list li{
  display: inline-block;
  margin-right: 20px;
}


.navbar-brand {
	display: flex;
	align-items: center;
  gap: 5px;
}


.search_result_item {
  text-decoration: none;
  background-color: #f3f1f1;
  display: block;
  padding: 15px;
  margin-bottom: 5px;

  &:hover {
    background-color: #d3d3d3;
  }

  .search_result_title {
    overflow: hidden;
    text-overflow: ellipsis;
    height: 25px;
    white-space: nowrap;
  }

  .search_result_content {
    color: #4f4f4f;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 20px;
    white-space: nowrap;
  }
}


.infinite-scroll-component {
  overflow-x: hidden !important
}




.extension_title {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}


/*
====================== DARK THEME
*/
.modal.dark .modal-content{
  background-color: #000;
  color: #fff;
}

.modal.dark .btn-close {
  background-color: #6a6a6a;
}


.modal.dark .modal-content input::placeholder,
.modal.dark .modal-content input{
  background-color: #262626;
  color: #fff !important;
}

.modal.dark .search_result_item {
  background-color: #202020;
  color: #848484;
}
.modal.dark .search_result_item .search_result_title{
  color: #bdbdbd
}

.navbar-dark  #message_wrap .message_room_title{
  color: #fff
}

.navbar-dark .message_wrap .message_element:hover {
  background-color: #000;
}



.navbar-dark .notification_element .notification_body {
  color: #fff !important
}

.navbar-dark .notification_wrap .notification_element:hover {
  background-color: #000 !important
}

/*
====================== END DARK THEME
*/



.show_animation {
  position: fixed;
  top: 80px;
  right: 60px;
  z-index: 10000;
  background-color: #fff;
  //width: 260px;
  padding: 16px;
  border-radius: 8px;
  border: 1px #e0e0e0 solid;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  display: flex;
  align-content: center;
  align-items: center;
}

.show_animation .view_animation_level_1{
  width: 100px;
  height: 100%;
}

.show_animation .show_animation_text{}

@media only screen and (max-width: 768px) {
  #main_container {
    margin-left: 0 !important;
  }
  .show_animation {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    top: unset !important;
    z-index: 10000;
    background-color: #fff;
    width: 100%;
    padding: 16px;
    border: 1px #e0e0e0 solid;
    border-radius: 0;
    box-shadow: 0px 0px 5px 1px #ccc;
    display: flex;
    align-content: center;
    align-items: center;
    height: 80px;
  }
}



.logo_text_channel {
  color: #535353;
  font-size: 18px;
  font-weight: 600;
  height: 40px;
  margin-left: 8px;
  line-height: 40px;
  width: 100%;
  font-weight: 400;

  .text-truncate {
    max-width: 222px;
    width: 161px;
    display: inline-block;
  }

}

.header_button_create_channel{
  display: flex;
  justify-content: center;
  max-width: 200px;
  width: max-content;
  align-items: center;
  background-color: var(--color-theme-primary);
  color: #fff;
  font-weight: 700;
  border-radius: 6px 6px 0 0;
  padding: 10px;
  text-decoration: none;
  position: fixed;
  bottom: 0;
  right: 30px;
  z-index: 3;
}


#single_page {


  h1 {
    font-size: 3.5rem;
    font-weight: bold;
    letter-spacing: -3px;
    line-height: 1;
    color: var(--color-theme-white)
  }

  .container {
    max-width: 900px;
  }

  #page_hero {
    overflow: hidden;
    color: #fff;
    background: url('./images/hero-default-background.webp') no-repeat center center #000;
    background-attachment: fixed;
    background-size: cover;
    min-height: 320px;
    align-items: center !important;
    display: flex;
  }

  .icon-lg {
    width: 3.5rem;
    height: 3.5rem;
    line-height: 3.5rem;
    text-align: center;
    font-size: 1.2rem;
  }

  .bg-orange {
    background-color: rgba(253, 126, 20, 0.1) !important;
  }

  .text-orange {
    --bs-text-opacity: 1;
    color: rgba(253, 126, 20, 1) !important;
  }

  .shadow {
    -webkit-box-shadow: 0px 0px 40px rgba(29, 58, 83, 0.1) !important;
    box-shadow: 0px 0px 40px rgba(29, 58, 83, 0.1) !important;
  }

  .card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    color: var(--bs-card-color);
  }
  .card {
    --bs-card-spacer-y: 1.25rem;
    --bs-card-spacer-x: 1.25rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-title-color: var(--bs-gray-900);
    --bs-card-subtitle-color: ;
    --bs-card-border-width: 0;
    --bs-card-border-color: var(--bs-border-color);
    --bs-card-border-radius: 1rem;
    --bs-card-box-shadow: ;
    --bs-card-inner-border-radius: 1rem;
    --bs-card-cap-padding-y: 1.25rem;
    --bs-card-cap-padding-x: 1.25rem;
    --bs-card-cap-bg: var(--bs-mode);
    --bs-card-cap-color: ;
    --bs-card-height: ;
    --bs-card-color: ;
    --bs-card-bg: var(--bs-mode);
    --bs-card-img-overlay-padding: 1.25rem;
    --bs-card-group-margin: 0.9375rem;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    height: var(--bs-card-height);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
  }

  h5 {
    font-weight: bold;
  }

}

div {
  font-size: medium;
  }

label {
  font-size: medium;
  }

.navbar-brand {
  position: relative;
}

.logo_beta_channel {
	height: 14px;
	font-size: 10px;
	line-height: 14px;
	padding: 0 4px;
	font-weight: 700;
	margin-left: 15px;
	position: absolute;
	top: -10px;
	left: 8px;
}


.topbar_channel_dropdown .dropdown-menu {
  left: 0 !important
}

.channel_element_list_wrap {

  .channel_element {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      padding: 1em 2em;
      transition: all 0.45s ease;
      text-decoration: none;
      margin-bottom: 5px;


      &:hover {
        background-color: #2f65b9;
        background-color: var(--color-theme-primary-light);
        color: #2f65b9;
        color: var(--color-theme-primary);
      }

    .channel_element_meta {

      display: block;
      text-decoration: none;
      width: 75%;

      .channel_element_meta_name {
        font-size: 0.875em;
        font-size: var(--font-size-normal);
        font-weight: 600;
        font-weight: var(--font-weight-semi-bold);
        line-height: 1.6em;
      }
    }
  }
}

.clickable {
  cursor: pointer;
}


.ReactModal__Overlay {
  z-index: 9999 !important;
}


.siq_bR{
  bottom: 90px !important;
}
